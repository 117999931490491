import React from 'react'
import { logout, ScrollToTop } from '../../shared/Helper'

export default class BaseClass<IProps, IState> extends React.Component<IProps, IState> {
    handleknownError = (errors) => {
        ScrollToTop()
        ;(this.props as any).setIsDataLoading(false)
        ;(this as any).setState({
            error: errors.detail,
            visible: true,
        })
    }

    handleUnknownError = (error) => {
        ScrollToTop()
        ;(this.props as any).setIsDataLoading(false)
        console.log(error)
        if (error.status === 401) {
            return this.logoutOfApp()
        }

        console.log(error)
        ;(this as any).setState({
            error: error.statusText,
        })
    }

    logoutOfApp = () => {
        ;(this.props as any).setIsDataLoading(true)

        // return logout();
    }

    showError = (message) => {
        ;(this.props as any).setIsDataLoading(false)
        ;(this as any).setState({ error: message, visible: true })
    }
}
