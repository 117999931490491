/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useCallback } from 'react'
import { withOktaAuth } from '@okta/okta-react'
import { AuthService, AuthState } from '../../../models/Auth'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap'
import { SchoolClassDto } from '../../../models/SchoolClass'
import { deleteSchoolClass, getSchoolClassesFromApi } from '../../../services/SchoolClassApi'
import {
    convertSchoolClassLevelLabels,
    archivedClassGuid,
    ScrollToTop,
} from '../../../shared/Helper'
import BreadCrumbs from '../../layout/BreadCrumbs'
import DemoPopovers from '../../layout/DemoPopovers'
import { TeacherDto } from '../../../models/Teacher'
import { ISessionData } from '../../../AppRouter'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'

interface IParams {
    schoolId: string
}

interface IProps extends RouteComponentProps<IParams> {
    authService: AuthService
    authState: AuthState
    // reloadNavBarClass: any;
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    onChangeSessionData: any
    match: any
    history: any
}

export interface HooksPageCore {
    handleUnknownError(error: any)
    handleknownError(error: any)
    setIsPageLoading: (value: boolean) => void
    setError: (value: string) => void
    setIsErrorVisible: (value: boolean) => void
    authenticatedTeacher: TeacherDto
}

export const useHooksPagecore = (authService: any): HooksPageCore => {
    const [error, setError] = useState('')
    const [isErrorVisible, setIsErrorVisible] = useState(false)
    const [authenticatedTeacher, setAuthenticatedTeacher] = useState(null)

    useEffect(() => {
        //getAuthenticatedTeacher(authService)
    }, [])

    const setIsPageLoading = useCallback((value: boolean): void => {
        // context.setShowScreenLoader(value);
    }, [])

    // const setPageIsLoading = (loading, messages?) => {
    //   setIsLoading(loading);
    //   setSpinnerText(messages)
    //   //  context.setScreenLoaderMessages(messages);
    //   //  context.setShowScreenLoader(loading);
    // };

    const handleknownError = (errors) => {
        ScrollToTop()
        //setIsLoading(false);
        setError(errors.detail)
        setIsErrorVisible(true)
    }

    const handleUnknownError = (error) => {
        ScrollToTop()
        // setIsLoading(false);
        console.log(error)
        if (error.status === 401) {
            // return logoutOfApp(authService);
            //  return logout();
        }

        console.log(error)
        setError(error.statusText)
    }

    const logoutOfApp = (authService) => {
        //  setIsLoading(true);
        //  return logout();
    }

    const showError = (message) => {
        //   setIsLoading(false);
        setError(message)
        setIsErrorVisible(true)
    }

    return {
        handleknownError,
        handleUnknownError,
        setIsPageLoading,
        setError,
        setIsErrorVisible,
        authenticatedTeacher,
    }
}

function SchoolClassIndex(props: IProps) {
    let history = useHistory()
    const { oktaAuth, authState, authService } = useOktaAuth()

    const [schoolClasses, setSchoolClasses] = useState(new Array<SchoolClassDto>())
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedClassToDelete, setSelectedClassToDelete] = useState(null)
    const core = useHooksPagecore(props.authService)

    useEffect(() => {
        loadData()
    }, [])

    // const handleknownError = (errors) => {
    //   ScrollToTop();
    //   core.setIsPageLoading(false);
    //   core.setError(errors.detail);
    //   core.setIsErrorVisible(true);
    // }

    // const handleUnknownError = (error) => {
    //   ScrollToTop();
    //   if (error.status === 401) {
    //     return logout(props.authService);
    //   }
    //   core.setIsPageLoading(false);
    //   console.log(error);
    //   core.setError(error.statusText);
    // }

    const loadData = async () => {
        // core.setIsPageLoading(true);
        await getUserSchoolClasses()
        // core.setIsPageLoading(false);
        props.setIsDataLoading(false)
    }

    const getUserSchoolClasses = async () => {
        return getSchoolClassesFromApi(props.authState.accessToken.accessToken, true)
            .then((res) => {
                if (res.ok) {
                    core.setIsPageLoading(false)
                    setSchoolClasses(res.data)
                    let updatedSessionData = {
                        ...props.sessionData,
                        schoolClasses: res.data,
                    }
                    props.onChangeSessionData(updatedSessionData)
                } else {
                    core.handleknownError(res.error)
                }
            })
            .catch((error) => {
                core.handleUnknownError(error)
            })
    }

    const deleteClass = async (schoolClassId: string) => {
        core.setIsPageLoading(true)
        let token = await props.authState.accessToken.accessToken
        await deleteSchoolClass(schoolClassId, props.sessionData.selectedSchool.id, token)
            .then(async (res) => {
                if (res.ok) {
                    loadData()
                } else {
                    core.handleknownError(res.error)
                }

                toggleDelete(null)
            })
            .catch((error) => {
                core.handleUnknownError(error)
            })
    }

    const manageStudents = (schoolClass) => {
        let updatedSessionData = {
            ...props.sessionData,
            selectedSchoolClass: schoolClass,
        }
        props.onChangeSessionData(updatedSessionData)
        ;(props.history as any).push('/student')
    }

    const toggleDelete = (schoolClass) => {
        setSelectedClassToDelete(schoolClass)
        setDeleteModal(!deleteModal)
    }

    // props.setIsDataLoading(isDataLoading);

    return (
        <div>
            {props.sessionData.authenticatedTeacher.isDemo && (
                <DemoPopovers
                    items={[
                        {
                            target: 'actions',
                            placement: 'top',
                            message: 'These features are not available for trial accounts.',
                        },
                    ]}
                ></DemoPopovers>
            )}
            <div className="mb-2">
                <BreadCrumbs
                    items={[
                        { title: 'Home', link: '/' },
                        {
                            title: 'Admin',
                            link: `/admin/select/${props.sessionData.selectedSchool.id}`,
                        },
                        { title: 'Classes', link: '' },
                    ]}
                />
            </div>
            <div>
                <h2 className="pb-2">Classes</h2>
            </div>
            <div className="mb-2">
                <Button
                    className="mr-1"
                    color="success"
                    tag={Link}
                    to={`/admin/class/create/${props.sessionData.selectedSchool.id}`}
                >
                    Add Class
                </Button>
                <Button
                    className="mr-1"
                    color="success"
                    tag={Link}
                    to={`/admin/class/bulk/${props.sessionData.selectedSchool.id}`}
                >
                    Add Classes in Bulk
                </Button>
                <Button className="mr-1" color="success" tag={Link} to={'/class/student/bulk'}>
                    Add Students in Bulk
                </Button>
            </div>
            <div>
                <Table bordered striped className="bg-white">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th style={{ width: '15%' }}>Year Level/s</th>
                            <th>Teachers</th>
                            <th>Calendar Year</th>
                            <th id="actions" style={{ width: '20%' }}>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!schoolClasses
                            ? null
                            : schoolClasses.map((schoolClass: SchoolClassDto) => {
                                  return (
                                      <tr key={schoolClass.id}>
                                          <td>{schoolClass.name}</td>
                                          <td>
                                              {convertSchoolClassLevelLabels(
                                                  schoolClass.levels
                                              ).join(', ')}
                                          </td>
                                          <td>
                                              {schoolClass.teachers
                                                  .map((v) => `${v.firstname[0]}. ${v.lastname}`)
                                                  .join(', ')}
                                          </td>
                                          <td>
                                              {schoolClass.calendarYear
                                                  ? schoolClass.calendarYear
                                                  : ''}
                                          </td>
                                          <td className="text-nowrap text-right">
                                              {schoolClass.id === archivedClassGuid ? null : (
                                                  <>
                                                      <Button
                                                          className="mr-2"
                                                          color="primary"
                                                          tag={Link}
                                                          to={`/admin/class/edit/${props.sessionData.selectedSchool.id}/${schoolClass.id}`}
                                                          disabled={
                                                              core.authenticatedTeacher &&
                                                              core.authenticatedTeacher.isDemo
                                                          }
                                                      >
                                                          Edit
                                                      </Button>
                                                      <Button
                                                          color="danger"
                                                          onClick={() => {
                                                              // deleteClass(schoolClass.id)
                                                              toggleDelete(schoolClass)
                                                          }}
                                                          disabled={
                                                              core.authenticatedTeacher &&
                                                              core.authenticatedTeacher.isDemo
                                                          }
                                                      >
                                                          Delete
                                                      </Button>{' '}
                                                  </>
                                              )}
                                              <Button
                                                  className=""
                                                  color="success"
                                                  onClick={() => manageStudents(schoolClass)}
                                                  disabled={
                                                      core.authenticatedTeacher &&
                                                      core.authenticatedTeacher.isDemo
                                                  }
                                              >
                                                  Manage students
                                              </Button>{' '}
                                          </td>
                                      </tr>
                                  )
                              })}
                    </tbody>
                </Table>
            </div>

            <Modal isOpen={deleteModal} toggle={toggleDelete}>
                <ModalHeader toggle={toggleDelete}>Delete School Class</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete{' '}
                    {selectedClassToDelete && selectedClassToDelete.name} class?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDelete}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={() => deleteClass(selectedClassToDelete.id)}>
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
export default withOktaAuth(SchoolClassIndex)
