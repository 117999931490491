/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { Button, Alert, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import logo from '../../assets/images/EarlyInsights_CMYK.svg'
import './Order.css'
import Select from 'react-select'
import { SchoolCombo } from './SchoolCombo'
import { CreateDemoApi } from '../../services/OrderApi'
import { FullPageSpinner } from '../layout/FullPageSpinner'
import ReactPixel from 'react-facebook-pixel'
import { ScrollToTop } from '../../shared/Helper'
import { useHistory } from 'react-router-dom'

const options = [
    { value: 'NSW', label: 'NSW' },
    { value: 'VIC', label: 'VIC' },
    { value: 'QLD', label: 'QLD' },
    { value: 'ACT', label: 'ACT' },
    { value: 'TAS', label: 'TAS' },
    { value: 'NT', label: 'NT' },
    { value: 'WA', label: 'WA' },
    { value: 'SA', label: 'SA' },
    { value: 'OTHER', label: 'OTHER' },
]

export enum ValidForDemoStatusEnum {
    Failed,
    PassedAsNewCustomer,
    PassedAsNewTeacherAndExistingCustomer,
    PassedAsExistingTeacherAndExistingCustomer,
    NeedsConfirmation,
}

interface IProps {
    // sessionData: ISessionData;
    setIsDataLoading: any
    isDataLoading: boolean
    // onSetSpinnerSentences :any
}

export default function Demo(props: IProps) {
    const [email, setEmail] = useState(null)
    const [school, setSchool] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [error, setError] = useState('')
    const [visible, setVisible] = useState(false)
    const [showMustSelectSchool, setShowMustSelectSchool] = useState(false)
    const [useCustomSchool, setUseCustomSchool] = useState(false)
    const [schoolName, setSchoolName] = useState('')
    const [schoolAddress, setSchoolAddress] = useState('')
    const [schoolAddress2, setSchoolAddress2] = useState('')
    const [schoolState, setSchoolState] = useState('')
    const [schoolSuburb, setSchoolSuburb] = useState('')
    const [schoolPostCode, setSchoolPostCode] = useState('')
    const [schoolPhone, setSchoolPhone] = useState('')
    //const [isOpen, setIsOpen] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false)
    const [disableSchools, setDisableSchools] = useState(false)

    const childRef = useRef()
    let history = useHistory()

    useEffect(() => {
        try {
            document.domain = 'mathspathway.com'
        } catch (error) {
            // console.log(error);
        }

        // override background colour
        document.querySelector('html').style.backgroundColor = 'white'
        document.getElementById('root').style.backgroundColor = 'white'

        ReactPixel.init('356309674824876')
        ReactPixel.pageView() // For tracking page view
        ReactPixel.track('InitiateCheckout') // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
    }, [])

    const onHandleChange = (e: any) => {
        if (childRef.current != null) {
            ;(childRef.current as any).changeAusState(e)
        }
    }

    const createDemo = () => {
        setVisible(false)
        setError('')
        props.setIsDataLoading(true, ['Please wait while we set up your demo...'])

        if (!useCustomSchool && !school) {
            setShowMustSelectSchool(true)
            props.setIsDataLoading(false)

            return
        }

        let demoCreateDto = {
            school: school ? school.label : null,
            email,
            firstName,
            lastName,
            //  createdDate: date.format(new Date(), 'YYYY-MM-DD HH:mm:ss'),
            useCustomSchool,
            customSchool: {
                schoolName,
                schoolSuburb,
                schoolState,
                schoolPostCode,
                schoolAddress,
                schoolAddress2,
                schoolPhone,
            },
        }

        return CreateDemoApi(demoCreateDto)
            .then((res) => {
                if (res.ok) {
                    props.setIsDataLoading(false)
                    setVisible(false)

                    return history.push('/demo/complete')
                }

                props.setIsDataLoading(false)
                setVisible(true)
                setError(res.error.detail)
                ScrollToTop()

                return ValidForDemoStatusEnum.Failed
            })
            .catch((error) => {
                props.setIsDataLoading(false)
                setVisible(true)
                setError(error.statusText)
                ScrollToTop()

                return ValidForDemoStatusEnum.Failed
            })
    }

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        await createDemo()
    }

    // const togglePopover = () => {
    //   setIsOpen(!isOpen);
    // }

    const toggleConfirm = () => {
        props.setIsDataLoading(false)
        setConfirmModal(!confirmModal)
    }

    const confirm = async () => {
        await createDemo()
    }

    //props.setIsDataLoading(isDataLoading);

    return (
        <div className="container bg-white">
            <div className="py-3 text-center">
                {/* <img className="d-block mx-auto mb-4" src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72"></img> */}
                <img
                    className="mx-auto d-block logo "
                    alt="logo"
                    style={{ width: '100%' }}
                    src={logo}
                ></img>
                {/* <h2>Checkout form</h2>
                <p className="lead">Below is an example form built entirely with Bootstrap's form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> */}
            </div>
            <div className="row">
                <div className="col-md-3 order-md-1"></div>
                <div className="col-md-6 order-md-1">
                    {visible ? (
                        <Alert color="warning">
                            <p>{error}</p>
                        </Alert>
                    ) : null}
                    <form className="needs-validation" onSubmit={onSubmit}>
                        <hr className="mb-3"></hr>
                        <h4 className="mb-3">Create trial account</h4>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    placeholder="John"
                                    required
                                    onChange={(e) => setFirstName(e.target.value)}
                                ></input>
                                <div className="invalid-feedback">
                                    Valid first name is required.
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    placeholder="Smith"
                                    required
                                    onChange={(e) => setLastName(e.target.value)}
                                ></input>
                                <div className="invalid-feedback">Valid last name is required.</div>
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email">
                                Email <span className="text-muted"></span>
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="you@example.com"
                                required
                                onChange={(e) => setEmail(e.target.value)}
                            ></input>
                            <div className="invalid-feedback">enter a valid email address.</div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="state">State</label>
                            <Select
                                id="state"
                                isDisabled={disableSchools}
                                options={options}
                                onChange={onHandleChange}
                            />
                        </div>
                        {useCustomSchool ? (
                            <></>
                        ) : (
                            <>
                                <div className="mb-2">
                                    <label htmlFor="school">School</label>
                                    <span id="school">
                                        <SchoolCombo
                                            ref={childRef}
                                            isDisabled={disableSchools}
                                            onChange={setSchool}
                                        />
                                    </span>
                                </div>
                                {showMustSelectSchool ? (
                                    <>
                                        <Alert color="warning">
                                            You must select a school from the dropdown. if you
                                            cannot find it please tick the box below.
                                        </Alert>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                        <div className="form-group">
                            <div className="custom-control mb-3" style={{ zIndex: 0 }}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="save-info-000"
                                    onChange={(e) => setUseCustomSchool(e.target.checked)}
                                ></input>
                                <label className="form-check-label" htmlFor="save-info-000">
                                    {' '}
                                </label>
                                I can’t find my school
                            </div>
                        </div>
                        {useCustomSchool ? (
                            <>
                                <div className="mb-2">
                                    <Alert color="warning">
                                        Please enter your school details — you must be associated
                                        with a school to proceed with your purchase. Early Insights
                                        is not currently available for homeschooling or private
                                        tutoring.
                                    </Alert>
                                    <div className="mb-2">
                                        <label htmlFor="Name">School Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Name"
                                            placeholder="Name"
                                            required
                                            onChange={(e) => setSchoolName(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="Address">School Address</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Address"
                                            placeholder="Address"
                                            required
                                            onChange={(e) => setSchoolAddress(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="Address2">School Address 2</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Address2"
                                            placeholder="Address2"
                                            onChange={(e) => setSchoolAddress2(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="Suburb">School Suburb</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Suburb"
                                            placeholder="Suburb"
                                            required
                                            onChange={(e) => setSchoolSuburb(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="state2">School State</label>
                                        <Select
                                            id="state2"
                                            options={options}
                                            onChange={(e) => setSchoolState((e as any).value)}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="Postcode">School Postcode</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="Postcode"
                                            placeholder="Postcode"
                                            required
                                            onChange={(e) => setSchoolPostCode(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="Phone">School Phone</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="Phone"
                                            placeholder="Phone"
                                            required
                                            onChange={(e) => setSchoolPhone(e.target.value)}
                                        ></input>
                                    </div>
                                </div>
                            </>
                        ) : null}
                        <hr className="mb-3"></hr>
                        <div className="row">
                            <div className="col">
                                <div className="mb-2">
                                    <button
                                        className="btn btn-success btn-lg btn-block"
                                        type="submit"
                                    >
                                        Start trial
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Modal isOpen={confirmModal} toggle={toggleConfirm}>
                                <ModalHeader toggle={toggleConfirm}>
                                    Continue with purchase?
                                </ModalHeader>
                                <ModalBody>
                                    Your school already has an Early Insights account. Do you want
                                    to purchase new student licences for your school?
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={toggleConfirm}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" onClick={confirm}>
                                        Yes
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </form>
                </div>
                <div className="col-md-3 order-md-1"></div>
            </div>
        </div>
    )
}
