import { getInternalApiUrl, startStudentDiagnosticApiUrl } from './../shared/ApiUrls'
import { AddStudentsToDiagnosticDto, StudentDiagnosticQuestionDto } from './../models/Diagnostic'
import axios from 'axios'
import { handleApiSuccess, handleApiError, IApiResult } from '../shared/ApiHandler'
import {
    diagnosticApiUrl,
    studentDiagnosticApiUrl,
    studentDiagnosticQuestionApiUrl,
    teacherBaseApiUrl,
    monteCarloDiagnosticApiUrl,
} from '../shared/ApiUrls'
import {
    DiagnosticDto,
    DiagnosticCreateDto,
    DiagnosticUpdateDto,
    DiagnosticStopDto,
    StudentDiagnosticDto,
    StudentDiagnosticUpdateDto,
    StudentDiagnosticFinishDto,
    DiagnosticEstimateDto,
} from '../models/Diagnostic'

export const finishStudentDiagnosticInterviewApi = (
    studentDiagnostic: StudentDiagnosticUpdateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(`${startStudentDiagnosticApiUrl}`, studentDiagnostic, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getDiagnosticsApi = (schoolClassId: string, token: string): Promise<IApiResult> => {
    return axios
        .get<DiagnosticDto[]>(`${diagnosticApiUrl}/GetDiagnostics/${schoolClassId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getDiagnosticApi = (
    schoolClassId: string,
    diagnosticId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .get<DiagnosticDto>(`${diagnosticApiUrl}/GetDiagnostic/${schoolClassId}/${diagnosticId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const addDiagnosticApi = (
    diagnostic: DiagnosticCreateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(diagnosticApiUrl, diagnostic, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const addStudentsToDiagnosticApi = (
    diagnostic: AddStudentsToDiagnosticDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(`${diagnosticApiUrl}/AddStudentsToDiagnostic`, diagnostic, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const updateDiagnosticNameApi = (
    schoolClassId: string,
    diagnostic: DiagnosticUpdateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .put(`${diagnosticApiUrl}/${schoolClassId}`, diagnostic, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const updateStudentDiagnosticNoteApi = (
    diagnosticId: string,
    studentId: string,
    note: string,
    token: string
): Promise<IApiResult> => {
    let data = JSON.stringify(note)

    return axios
        .put(
            `${studentDiagnosticApiUrl}/UpdateStudentDiagnosticNote/${diagnosticId}/${studentId}`,
            data,
            {
                headers: {
                    Authorization: `bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const stopDiagnosticApi = (dto: DiagnosticStopDto, token: string): Promise<IApiResult> => {
    return axios
        .post(`${diagnosticApiUrl}/stop`, dto, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const deleteDiagnosticApi = (
    schoolClassId: string,
    diagnosticId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .delete(`${diagnosticApiUrl}/${schoolClassId}/${diagnosticId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getStudentDiagnosticsByDiagnosticIdApi = (
    diagnosticId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .get<StudentDiagnosticDto[]>(`${studentDiagnosticApiUrl}/${diagnosticId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getStudentDiagnosticsByStudentIdApi = (
    studentId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .get<StudentDiagnosticDto[]>(`${studentDiagnosticApiUrl}/GetByStudentId/${studentId}`, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getStudentDiagnosticInterviewQuestionsApi = (
    diagnosticId: string,
    studentId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .get<StudentDiagnosticFinishDto>(
            `${studentDiagnosticQuestionApiUrl}/GetStudentDiagnosticInterviewQuestions/${diagnosticId}/${studentId}`,
            {
                headers: { Authorization: `bearer ${token}` },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getAnsweredStudentDiagnosticQuestionsApi = (
    diagnosticId: string,
    studentId: string,
    token: string
): Promise<IApiResult> => {
    return axios
        .get<StudentDiagnosticFinishDto>(
            `${studentDiagnosticQuestionApiUrl}/GetAnsweredStudentDiagnosticQuestions/${diagnosticId}/${studentId}`,
            {
                headers: { Authorization: `bearer ${token}` },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const stopStudentDiagnosticApi = (
    studentDiagnostic: StudentDiagnosticUpdateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(`${studentDiagnosticApiUrl}/stop`, studentDiagnostic, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getEstimatesForNewDiagnosticApi = (
    diagnostic: DiagnosticCreateDto,
    token: string,
    source: any
): Promise<IApiResult> => {
    diagnostic.name = 'fake assessment name'
    return axios
        .post(`/api/MonteCarloTimeForNewDiagnostic`, diagnostic, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: getInternalApiUrl(),
            cancelToken: source.token,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const getEstimatesForExistingDiagnosticApi = (
    diagnosticId: string,
    token: string,
    source: any
): Promise<IApiResult> => {
    return axios
        .post(`/api/MonteCarloTimeForExistingDiagnostic/${diagnosticId}`, null, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: getInternalApiUrl(),
            cancelToken: source.token,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const preLoadMonteCarloCacheApi = (
    schoolClassId: string,
    token: string,
    source: any
): Promise<IApiResult> => {
    return axios
        .post(`${monteCarloDiagnosticApiUrl}/PreLoadMonteCarloCache/${schoolClassId}`, null, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: getInternalApiUrl(),
            cancelToken: source.token,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const saveEstimatesForExistingDiagnosticApi = (
    diagnosticId: string,
    diagnosticEstimateDto: DiagnosticEstimateDto,
    token: string
): Promise<IApiResult> => {
    return axios
        .post(
            `${monteCarloDiagnosticApiUrl}/SaveMonteCarloTimeEstimates/${diagnosticId}`,
            diagnosticEstimateDto,
            {
                headers: { Authorization: `bearer ${token}` },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const sendFeedbackApi = (
    feedback: string,
    diagnosticId: string,
    studentId: string,
    questionId: string,
    questionNumber: number,
    teacherId: string,
    token: string
): Promise<IApiResult> => {
    let data = JSON.stringify(feedback)
    return axios
        .post(
            `${diagnosticApiUrl}/SendFeedback/${diagnosticId}/${studentId}/${questionId}/${questionNumber}/${teacherId}`,
            data,
            {
                headers: {
                    Authorization: `bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                baseURL: teacherBaseApiUrl,
            }
        )
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export const overrideAnswerApi = (token: string, request: OverrideRequest): Promise<IApiResult> => {
    return axios
        .post(`${studentDiagnosticQuestionApiUrl}/Override`, request, {
            headers: { Authorization: `bearer ${token}` },
            baseURL: teacherBaseApiUrl,
        })
        .then(handleApiSuccess)
        .catch(handleApiError)
}

export class OverrideRequest {
    studentId: string
    diagnosticId: string
    questionId: string
}
