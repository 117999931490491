/* eslint-disable no-unused-vars */
/* eslint-disable no-inner-declarations */
import React, { useState, useRef, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Button, Alert, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import logo from '../../assets/images/EarlyInsights_CMYK.svg'
import './Order.css'
import Select from 'react-select'
import { SchoolCombo } from './SchoolCombo'
import {
    CheckTeacherIsValidForOrderApi as CheckOrderIsValidFromApi,
    CreateStripeIntentOrder,
} from '../../services/OrderApi'
import NumberFormat from 'react-number-format'
import stripeBadge from '../../assets/images/Powered by Stripe - blurple.svg'
import { FullPageSpinner } from '../layout/FullPageSpinner'
import ReactPixel from 'react-facebook-pixel'
import { ISessionData } from '../../AppRouter'

// interface IParams {
//   schoolId: string;
//   teacherId: string;
// }

interface IProps extends RouteComponentProps<any> {
    applyDiscount: boolean
    inPortal: boolean
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
}

const options = [
    { value: 'NSW', label: 'NSW' },
    { value: 'VIC', label: 'VIC' },
    { value: 'QLD', label: 'QLD' },
    { value: 'ACT', label: 'ACT' },
    { value: 'TAS', label: 'TAS' },
    { value: 'NT', label: 'NT' },
    { value: 'WA', label: 'WA' },
    { value: 'SA', label: 'SA' },
    { value: 'OTHER', label: 'OTHER' },
]

export enum CreateOrderValidationEnum {
    Failed,
    NewCustomer,
    NewTeacherAndExistingCustomer,
    ExistingTeacherAndExistingCustomer,
    SchoolDoesNotExist,
}

export default function Order(props: IProps) {
    const [disableSchools, setDisableSchools] = useState(false)
    const [email, setEmail] = useState(null)
    const [quantity, setQuantity] = useState(null)
    const [schoolOption, setSchoolOption] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [months, setMonths] = useState('12')
    const [error, setError] = useState('')
    const [visible, setVisible] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [showMustSelectSchool, setMustSelectSchool] = useState(false)
    const [useCustomSchool, setUseCustomSchool] = useState(false)
    const [customSchoolName, setCustomSchoolName] = useState('')
    const [customSchoolAddress, setCustomSchoolAddress] = useState('')
    const [customSchoolAddress2, setCustomSchoolAddress2] = useState('')
    const [customSchoolState, setCustomSchoolState] = useState('')
    const [customSchoolSuburb, setCustomSchoolSuburb] = useState('')
    const [customSchoolPostCode, setCustomSchoolPostCode] = useState('')
    const [customSchoolPhone, setCustomSchoolPhone] = useState('')
    const [confirmModal, setConfirmModal] = useState(false)
    // const [spinnerSentences, setSpinnerSentences] = useState([])

    const pricePerLicense = 20
    const discountedPricePerLicense = 9
    const childRef = useRef()
    let history = useHistory()

    useEffect(() => {
        props.sessionData && loadData()
    }, [])

    const loadData = () => {
        if (!props.inPortal) {
            try {
                document.domain = 'mathspathway.com'
            } catch (error) {
                // console.log(error);
            }
            // override background colour
            document.querySelector('html').style.backgroundColor = 'white'
            document.getElementById('root').style.backgroundColor = 'white'

            ReactPixel.init('356309674824876')
            ReactPixel.pageView() // For tracking page view
            ReactPixel.track('InitiateCheckout') // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
        } else {
            async function setExistingSchoolValuesAsync() {
                const teacher = props.sessionData.authenticatedTeacher
                if (!teacher) {
                    return
                }

                setSchoolOption({ value: '', label: teacher.schoolName })
                setEmail(teacher.email)
                setFirstName(teacher.firstname)
                setLastName(teacher.lastname)
            }

            setExistingSchoolValuesAsync()
        }
    }

    const onHandleChange = (e: any) => {
        if (childRef.current != null) {
            ;(childRef.current as any).changeAusState(e)
        }
    }

    const checkOrderIsValid = () => {
        setVisible(false)
        setError('')
        let school = useCustomSchool ? customSchoolName : schoolOption.label

        return CheckOrderIsValidFromApi(email, school)
            .then((res) => {
                if (res.ok) {
                    return res.data
                }

                props.setIsDataLoading(false)
                setVisible(true)
                setError(res.error.detail)

                return CreateOrderValidationEnum.Failed
            })
            .catch((error) => {
                props.setIsDataLoading(false)
                setVisible(true)
                setError(error.statusText)

                return CreateOrderValidationEnum.Failed
            })
    }

    const createOrder = async (confirmed) => {
        props.setIsDataLoading(true, ['Please wait a minute while we set up your order...'])
        setMustSelectSchool(false)

        if (!useCustomSchool && !schoolOption) {
            setMustSelectSchool(true)
            props.setIsDataLoading(false)
            return
        }

        if (quantity <= 0) {
            props.setIsDataLoading(false)
            setVisible(true)
            setError('The licence quantity must be higher than 0.')
            return
        }

        let createCustomerValidation: any

        if (confirmed) {
            createCustomerValidation = CreateOrderValidationEnum.NewTeacherAndExistingCustomer
        } else {
            createCustomerValidation = await checkOrderIsValid()
            switch (createCustomerValidation) {
                case CreateOrderValidationEnum.NewTeacherAndExistingCustomer:
                    return setConfirmModal(true)
                //  case CreateOrderValidationEnum.SchoolDoesNotExist: {}
                case CreateOrderValidationEnum.Failed:
                    return
                default:
                    console.log('createCustomerValidation not found')
            }
        }

        CreateStripeIntentOrder({
            school: useCustomSchool ? customSchoolName : schoolOption.label,
            quantity,
            email,
            months,
            firstName,
            lastName,
            isPuchaseOrder: false,
            useCustomSchool,
            customSchool: {
                schoolName: customSchoolName,
                schoolSuburb: customSchoolSuburb,
                schoolState: customSchoolState,
                schoolPostCode: customSchoolPostCode,
                schoolAddress: customSchoolAddress,
                schoolAddress2: customSchoolAddress2,
                schoolPhone: customSchoolPhone,
            },
            applyDiscount: !!props.applyDiscount,
            // createdDate: date.format(new Date(), 'YYYY-MM-DD HH:mm:ss')
        })
            .then((res) => {
                if (res.ok) {
                    props.setIsDataLoading(false)
                    if (props.inPortal) {
                        history.push(
                            `/admin/order/payment/${res.data.intentId}/${(
                                createCustomerValidation as number
                            ).toString()}`
                        )
                    } else {
                        history.push(
                            `/order/payment/${res.data.intentId}/${(
                                createCustomerValidation as number
                            ).toString()}`
                        )
                    }
                } else {
                    props.setIsDataLoading(false)
                    setVisible(true)
                    setError(res.error.detai)
                }
            })
            .catch((error) => {
                props.setIsDataLoading(false)
                setVisible(true)
                setError(error.statusText)
            })
    }

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        createOrder(false)
    }

    const setLicences = (e) => {
        let price = pricePerLicense
        let applyDiscount = props.applyDiscount

        if (applyDiscount) {
            price = discountedPricePerLicense
        }

        let value = e.target.value

        if (e.target.value === '') {
            value = 0
        }

        setTotalPrice(parseInt(value) * price)
        setQuantity(value)
    }

    // const togglePopover = () => {
    //   setIsOpen(!isOpen);
    // }

    const toggleConfirm = () => {
        props.setIsDataLoading(false)
        setConfirmModal(!confirmModal)
    }

    const confirm = async () => {
        props.setIsDataLoading(true)
        setConfirmModal(!confirmModal)
        await createOrder(true)
    }

    return (
        <div data-cy="order-page-loaded" className="container">
            {/* {isLoading ? (
        <>
          <FullPageSpinner
            sentences={spinnerSentences}
          ></FullPageSpinner>
        </>
      ) : (
        <></>
      )} */}
            <div className="py-3 text-center">
                {/* <img className="d-block mx-auto mb-4" src="https://getbootstrap.com/docs/4.0/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72"></img> */}
                <img
                    className="mx-auto d-block logo "
                    alt="logo"
                    style={{ width: '100%' }}
                    src={logo}
                ></img>
                {/* <h2>Checkout form</h2>
                <p className="lead">Below is an example form built entirely with Bootstrap's form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> */}
            </div>
            <div className="row">
                <div className="col-md-3 order-md-1"></div>
                <div className="col-md-6 order-md-1">
                    {visible ? (
                        <Alert color="warning">
                            <p>{error}</p>
                        </Alert>
                    ) : null}
                    <form className="needs-validation" onSubmit={onSubmit}>
                        {!props.inPortal ? (
                            <>
                                <hr className="mb-3"></hr>
                                <h4 className="mb-3">Order details</h4>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="firstName">First Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstName"
                                            placeholder="John"
                                            required
                                            onChange={(e) => setFirstName(e.target.value)}
                                        ></input>
                                        <div className="invalid-feedback">
                                            Valid first name is required.
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            placeholder="Smith"
                                            required
                                            onChange={(e) => setLastName(e.target.value)}
                                        ></input>
                                        <div className="invalid-feedback">
                                            Valid last name is required.
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="email">
                                        Email <span className="text-muted"></span>
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        placeholder="you@example.com"
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                    ></input>
                                    <div className="invalid-feedback">
                                        enter a valid email address.
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="state">State</label>
                                    <Select
                                        id="state"
                                        isDisabled={disableSchools}
                                        options={options}
                                        onChange={onHandleChange}
                                    />
                                </div>
                                {useCustomSchool ? (
                                    <></>
                                ) : (
                                    <>
                                        <div className="mb-2">
                                            <label htmlFor="school">School</label>
                                            <span id="school">
                                                <SchoolCombo
                                                    ref={childRef}
                                                    isDisabled={disableSchools}
                                                    onChange={setSchoolOption}
                                                />
                                            </span>
                                        </div>
                                        {showMustSelectSchool ? (
                                            <>
                                                <Alert color="warning">
                                                    You must select a school from the dropdown. if
                                                    you cannot find it please tick the box below.
                                                </Alert>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                                <div className="form-group">
                                    <div className="custom-control mb-3" style={{ zIndex: 0 }}>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="save-info-000"
                                            onChange={(e) => setUseCustomSchool(e.target.checked)}
                                        ></input>
                                        <label className="form-check-label" htmlFor="save-info-000">
                                            {' '}
                                        </label>
                                        I can’t find my school
                                    </div>
                                </div>
                                {useCustomSchool ? (
                                    <>
                                        <div className="mb-2">
                                            <Alert color="warning">
                                                Please enter your school details — you must be
                                                associated with a school to proceed with your
                                                purchase. Early Insights is not currently available
                                                for homeschooling or private tutoring.
                                            </Alert>
                                            <div className="mb-2">
                                                <label htmlFor="Name">School Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="Name"
                                                    placeholder="Name"
                                                    required
                                                    onChange={(e) =>
                                                        setCustomSchoolName(e.target.value)
                                                    }
                                                ></input>
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="Address">School Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="Address"
                                                    placeholder="Address"
                                                    required
                                                    onChange={(e) =>
                                                        setCustomSchoolAddress(e.target.value)
                                                    }
                                                ></input>
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="Address2">School Address 2</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="Address2"
                                                    placeholder="Address2"
                                                    onChange={(e) =>
                                                        setCustomSchoolAddress2(e.target.value)
                                                    }
                                                ></input>
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="Suburb">School Suburb</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="Suburb"
                                                    placeholder="Suburb"
                                                    required
                                                    onChange={(e) =>
                                                        setCustomSchoolSuburb(e.target.value)
                                                    }
                                                ></input>
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="state2">School State</label>
                                                <Select
                                                    id="state2"
                                                    options={options}
                                                    onChange={(e) =>
                                                        setCustomSchoolState((e as any).value)
                                                    }
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="Postcode">School Postcode</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="Postcode"
                                                    placeholder="Postcode"
                                                    required
                                                    onChange={(e) =>
                                                        setCustomSchoolPostCode(e.target.value)
                                                    }
                                                ></input>
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="Phone">School Phone</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="Phone"
                                                    placeholder="Phone"
                                                    required
                                                    onChange={(e) =>
                                                        setCustomSchoolPhone(e.target.value)
                                                    }
                                                ></input>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : null}
                        <hr className="mb-3"></hr>
                        <div className="mb-2">
                            <h4 className="mb-3">Number of Licences</h4>
                        </div>
                        <div className="mb-3">
                            <div className="mb-3">
                                <input
                                    data-cy="number-of-licences-input"
                                    type="number"
                                    min="1"
                                    className="form-control"
                                    id="licences"
                                    placeholder="Total number of students"
                                    required
                                    onChange={(e) => setLicences(e)}
                                ></input>
                            </div>
                            <div>
                                <span style={{ fontSize: 15 }}>
                                    <strong>
                                        {' '}
                                        <NumberFormat
                                            value={totalPrice}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                        />{' '}
                                        (Excl GST)
                                    </strong>
                                </span>
                                <span style={{ fontSize: 12 }}>
                                    {' '}
                                    <NumberFormat
                                        value={totalPrice + totalPrice * 0.1}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                    />{' '}
                                    (Incl GST)
                                </span>
                            </div>
                        </div>
                        <hr className="mb-4"></hr>
                        <div className="row">
                            <div className="col">
                                <div className="mb-2">
                                    <button
                                        data-cy="go-to-payment-button"
                                        className="btn btn-success btn-lg btn-block"
                                        type="submit"
                                    >
                                        Go to payment
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mt-2 center">
                                    {/* Your payment is secured by Stripe */}
                                    <img
                                        className="mx-auto d-block logo "
                                        alt="logo"
                                        style={{ width: '150px', height: '34px' }}
                                        src={stripeBadge}
                                    ></img>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Modal isOpen={confirmModal} toggle={toggleConfirm}>
                                <ModalHeader toggle={toggleConfirm}>
                                    Continue with purchase?
                                </ModalHeader>
                                <ModalBody>
                                    Your school already has an Early Insights account. Do you want
                                    to purchase new student licences for your school?
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={toggleConfirm}>
                                        Cancel
                                    </Button>
                                    <Button color="primary" onClick={confirm}>
                                        Yes
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </form>
                </div>
                <div className="col-md-3 order-md-1"></div>
            </div>
        </div>
    )
}
