import React, { useState, useEffect, useRef } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Button, Table, Alert, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import {
    ContentSettingsType,
    getStudentsApi,
    UpdateContentSettingsApi,
    UpdateContentSettingsForSchoolClassApi,
    UpdateGameRatioSettingsApi,
    UpdateGameRatioSettingsForSchoolClassApi,
} from '../../services/SpacedPracticeApi'
import './SpacedPractice.css'
import { useOktaAuth } from '@okta/okta-react'
import {
    archivedClassGuid,
    logout,
    ScrollToTop,
    isTeacherAdminOrSuper,
    getDateNow,
    getDate7DaysAgo,
    getDateLast6DaysAgo,
    getDateTomorrow,
    convertLocalToUtc,
} from '../../shared/Helper'
import BreadCrumbs from '../layout/BreadCrumbs'
import DemoPopovers from '../layout/DemoPopovers'
import { ISessionData } from '../../AppRouter'
import { FiHelpCircle } from 'react-icons/fi'
import Select from 'react-select'
import { KeyConceptPicker } from '../data/KeyConceptPicker'
import { DateTimeUtils } from '@microsoft/applicationinsights-common'
import moment from 'moment'

interface IParams {}

interface IProps extends RouteComponentProps<IParams> {
    sessionData: ISessionData
    setIsDataLoading: any
    isDataLoading: boolean
    match: any
    history: any
}

export enum GamesRatioType {
    NoGames,
    Games25Percent,
    Games50Percent,
    Games75Percent,
    AllGames,
}

export default function SpacedPractice(props: IProps) {
    const [dto, setDto] = useState(null)
    const [error, setError] = useState(null)
    const [selectedSchoolClassId, setSelectedSchoolClassId] = useState('')
    const [title, setTitle] = useState('Spaced Practice ()')
    const [selectedStudentId, setSelectedModalStudentId] = useState(null)
    const [contentModalVisible, setContentModalVisible] = useState(false)
    const [gameModalVisible, setGameModalVisible] = useState(false)
    const [start, setStart] = useState(getDateLast6DaysAgo())
    const [end, setEnd] = useState(getDateTomorrow())
    const { authState, authService } = useOktaAuth()
    const [dataFilter, setDataFilter] = useState(null)
    const [contentType, setContentType] = useState(ContentSettingsType.Default)
    const [contentSettingsOverride, setContentSettingsOverride] = useState(false)
    const [focusKeyConceptIds, setFocusKeyConceptIds] = useState([])
    const [gamesRatioType, setGamesRatioType] = useState(GamesRatioType.Games25Percent)
    const [gameRatioOverride, setGameRatioOverride] = useState(false)

    // const useHasChanged= (val: any) => {
    //   const prevVal = usePrevious(val)
    //   return prevVal !== val
    //   }

    //   const usePrevious = (value) => {
    //       const ref = useRef();
    //       useEffect(() => {
    //         ref.current = value;
    //       });
    //       return ref.current;
    // }

    // const hasVal1Changed = useHasChanged(props.sessionData.selectedSchoolClass)

    const gameRatioOptions = [
        {
            value: GamesRatioType.NoGames,
            questionLabel: '100% questions',
            gameLabel: '0% games',
            label: '100% questions 0% games',
        },
        {
            value: GamesRatioType.Games25Percent,
            questionLabel: '75% questions',
            gameLabel: '25% games',
            label: '75% questions 25% games',
        },
        {
            value: GamesRatioType.Games50Percent,
            questionLabel: '50% questions',
            gameLabel: '50% games',
            label: '50% questions 50% games',
        },
        {
            value: GamesRatioType.Games75Percent,
            questionLabel: '25% questions',
            gameLabel: '75% games',
            label: '25% questions 75% games',
        },
        {
            value: GamesRatioType.AllGames,
            questionLabel: '0% questions',
            gameLabel: '100% games',
            label: '0% questions 100% games',
        },
    ]

    useEffect(() => {
        loadData()
    }, [props.sessionData])

    const loadData = async () => {
        props.setIsDataLoading(true)
        const { authenticatedTeacher, selectedSchoolClass } = props.sessionData
        if (selectedSchoolClass) {
            setTitle(`Spaced Practice (${selectedSchoolClass.name})`)
            setSelectedSchoolClassId(selectedSchoolClass.id)
            await getStudents(
                selectedSchoolClass.id,
                convertLocalToUtc(start),
                convertLocalToUtc(end)
            )
        } else {
            if (isTeacherAdminOrSuper(authenticatedTeacher.accessLevel)) {
                props.history.push(`/admin/class/${authenticatedTeacher.schoolId}`)
            } else {
                props.history.push('/no-data-yet')
            }
        }
        props.setIsDataLoading(false)
    }

    const handleknownError = (errors) => {
        ScrollToTop()
        props.setIsDataLoading(false)
        setError(errors.detail)
    }

    const handleUnknownError = (error) => {
        ScrollToTop()
        if (error.status === 401) {
            //   return logout(authService);
        }

        props.setIsDataLoading(false)
        console.log(error)
        setError(error.statusText)
    }

    const getStudents = async (schoolClassId: string, start: string, end: string) => {
        props.setIsDataLoading(true)
        return getStudentsApi(authState.accessToken.accessToken, schoolClassId, start, end)
            .then((res) => {
                if (res.ok) {
                    setDto(res.data)
                } else {
                    handleknownError(res.error)
                }
                props.setIsDataLoading(false)
            })
            .catch((error) => {
                handleUnknownError(error)
            })
    }

    const onDataFilterChange = (selectedOption: any) => {
        setDataFilter(selectedOption)
        if (selectedOption?.value === 'Last_7_days') {
            getStudents(props.sessionData.selectedSchoolClass.id, getDate7DaysAgo(), getDateNow())
        } else if (selectedOption?.value === 'Custom') {
            getStudents(props.sessionData.selectedSchoolClass.id, start, end)
        }
    }

    const dataFilterOptions = [
        { value: 'Last_7_days', label: 'Last 7 days' },
        { value: 'Custom', label: 'Custom date' },
    ]

    // const gameSettingsOptions = [
    //   { value: "75%_questions_25%_games", label: "75% questions, 25% games" },
    //   { value: "50%_questions_50%_games", label: "50% questions, 50% games" },
    //   { value: "25%_questions_75%_games", label: "25% questions, 75% games" },
    // ];

    const toggleContentModalVisible = () => {
        setContentModalVisible(!contentModalVisible)
    }

    const toggleGameModalVisible = () => {
        setGameModalVisible(!gameModalVisible)
    }

    const saveContentSettings = () => {
        if (selectedStudentId === null) {
            return UpdateContentSettingsForSchoolClassApi(
                authState.accessToken.accessToken,
                props.sessionData.selectedSchoolClass.id,
                contentType,
                contentSettingsOverride,
                focusKeyConceptIds
            )
                .then((res) => {
                    if (res.ok) {
                        //  setDto(res.data);
                        loadData()
                    } else {
                        handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    handleUnknownError(error)
                })
        } else {
            return UpdateContentSettingsApi(
                authState.accessToken.accessToken,
                selectedStudentId,
                contentType,
                focusKeyConceptIds
            )
                .then((res) => {
                    if (res.ok) {
                        //  setDto(res.data);
                        loadData()
                    } else {
                        handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    handleUnknownError(error)
                })
        }
    }

    const saveGameSettings = (studentId, ratio) => {
        if (studentId === null) {
            return UpdateGameRatioSettingsForSchoolClassApi(
                authState.accessToken.accessToken,
                props.sessionData.selectedSchoolClass.id,
                gameRatioOverride,
                ratio
            )
                .then((res) => {
                    if (res.ok) {
                        //  setDto(res.data);
                        loadData()
                    } else {
                        handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    handleUnknownError(error)
                })
        } else {
            return UpdateGameRatioSettingsApi(authState.accessToken.accessToken, studentId, ratio)
                .then((res) => {
                    if (res.ok) {
                        //  setDto(res.data);
                        loadData()
                    } else {
                        handleknownError(res.error)
                    }
                })
                .catch((error) => {
                    handleUnknownError(error)
                })
        }
    }

    // if (props.isDataLoading) return null;

    return (
        <div data-cy="spaced-practice-page-loaded">
            {props.sessionData.authenticatedTeacher.isDemo && (
                <DemoPopovers
                    items={[
                        {
                            target: 'show-data-for',
                            placement: 'top',
                            message: 'These features are not available for trial accounts.',
                        },
                    ]}
                ></DemoPopovers>
            )}

            <div className="pb-2">
                <div className="mb-2">
                    <BreadCrumbs
                        items={[
                            { title: 'Home', link: '/' },
                            { title: 'Spaced Practice', link: '' },
                        ]}
                    />
                </div>
                <h2 className="pb-2">{title}</h2>
                {error ? (
                    <Alert color="warning">
                        <p>{error}</p>
                    </Alert>
                ) : null}

                {selectedSchoolClassId !== archivedClassGuid && (
                    <div className="pt-2 pb-1 row">
                        <div className="col-3">
                            <label
                                htmlFor="show-data-for"
                                className="text-nowrap"
                                style={{ fontWeight: 'bold' }}
                            >
                                Show data for
                            </label>
                            <Select
                                id="show-data-for"
                                value={dataFilter ? dataFilter : dataFilterOptions[0]}
                                onChange={onDataFilterChange}
                                options={dataFilterOptions}
                            />
                        </div>

                        {dataFilter?.value === 'Custom' && (
                            <>
                                <div className="col-3">
                                    <label
                                        htmlFor="start-date"
                                        className="text-nowrap"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Start date
                                    </label>
                                    <input
                                        type="date"
                                        id="start-date"
                                        className="form-control"
                                        required
                                        value={start}
                                        onChange={(event) => {
                                            let val = event.currentTarget.value
                                            setStart(val)

                                            if (dateIsInvalid(val)) return

                                            getStudents(
                                                props.sessionData.selectedSchoolClass.id,
                                                val,
                                                end
                                            )
                                        }}
                                    ></input>
                                </div>

                                <div className="col-3">
                                    <label
                                        htmlFor="end-date"
                                        className="text-nowrap"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        End date
                                    </label>
                                    <input
                                        type="date"
                                        id="end-date"
                                        className="form-control"
                                        required
                                        value={end}
                                        onChange={(event) => {
                                            let val = event.currentTarget.value
                                            setEnd(val)

                                            if (dateIsInvalid(val)) return

                                            getStudents(
                                                props.sessionData.selectedSchoolClass.id,
                                                start,
                                                val
                                            )
                                        }}
                                    ></input>
                                </div>
                            </>
                        )}
                    </div>
                )}
                <Table striped className="bg-white table-outer-border">
                    <thead>
                        <tr>
                            <th style={{ width: '21%' }} className="align-middle">
                                Full name
                            </th>
                            <th
                                style={{ width: '11%' }}
                                className="align-middle text-nowrap"
                                title={'Excludes time spent on games'}
                            >
                                At school <FiHelpCircle></FiHelpCircle>
                            </th>
                            <th style={{ width: '11%' }} className="align-middle text-nowrap">
                                At home
                            </th>
                            <th style={{ width: '8%' }} className="align-middle">
                                Answers
                            </th>
                            <th className="align-middle text-nowrap" style={{ width: '21%' }}>
                                Content settings
                                <a
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setSelectedModalStudentId(null)
                                        setFocusKeyConceptIds(dto.schoolClassFocusKeyConceptIds)
                                        setContentType(dto.schoolClassContentType)
                                        setContentModalVisible(true)
                                    }}
                                    style={{ paddingLeft: 10 }}
                                    className="text-nowrap"
                                >
                                    Edit all
                                </a>
                            </th>
                            <th className="align-middle text-nowrap">
                                Game Settings
                                <a
                                    href=""
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setSelectedModalStudentId(null)
                                        setGamesRatioType(dto.schoolClassGameRatioType)
                                        setGameModalVisible(true)
                                    }}
                                    style={{ paddingLeft: 10 }}
                                    className="text-nowrap"
                                >
                                    Edit all
                                </a>
                            </th>
                        </tr>
                    </thead>
                    <tbody data-cy="student-table-body">
                        {dto &&
                            dto.students.map((student: any) => {
                                return (
                                    <tr key={student.id}>
                                        <td
                                            data-cy="full-name"
                                            className="align-middle font-weight-bold "
                                        >
                                            <Link to={`/student/profile/${student.id}`}>
                                                {`${student.fullName}`}
                                            </Link>
                                        </td>
                                        <td className="align-middle text-nowrap">
                                            {student.schoolMinutes} minutes
                                        </td>
                                        <td className="align-middle text-nowrap">
                                            {student.homeMinutes} minutes
                                        </td>
                                        <td className="align-middle font-weight-bold ">
                                            <Link
                                                to={`/spaced-practice/result/${student.id}/${start}/${end}`}
                                            >
                                                View
                                            </Link>
                                        </td>
                                        <td className="align-middle">
                                            {student.contentType === 0
                                                ? 'Default settings'
                                                : 'Custom settings'}
                                            <a
                                                className="font-weight-bold "
                                                style={{ paddingLeft: 20 }}
                                                href=""
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setSelectedModalStudentId(student.id)
                                                    setFocusKeyConceptIds(
                                                        student.focusKeyConceptIds
                                                    )
                                                    setContentType(student.contentType)
                                                    setContentModalVisible(true)
                                                }}
                                            >
                                                Edit
                                            </a>
                                        </td>
                                        <td className="align-middle text-nowrap">
                                            <Select
                                                isMulti={false}
                                                id="game-settings"
                                                value={gameRatioOptions.find(
                                                    (v) => v.value === student.gameRatioType
                                                )}
                                                onChange={(e) => {
                                                    saveGameSettings(student.id, e.value)
                                                }}
                                                options={gameRatioOptions}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            </div>
            <Modal size="lg" isOpen={contentModalVisible} toggle={toggleContentModalVisible}>
                <ModalHeader style={{ display: 'block', textAlign: 'center', borderStyle: 'none' }}>
                    Spaced Practice Content Settings
                </ModalHeader>
                <ModalBody style={{ padding: 50, paddingBottom: 10, paddingTop: 10 }}>
                    Content
                    <div className="pb-2">
                        <div
                            className=""
                            style={{
                                border: '1px solid black',
                                borderRadius: 5,
                                padding: 10,
                            }}
                        >
                            <div className="custom-control custom-radio ">
                                <div>
                                    <input
                                        key="radio-content-settings-default"
                                        id="default"
                                        name="contentSettings"
                                        type="radio"
                                        className="custom-control-input"
                                        checked={contentType === ContentSettingsType.Default}
                                        onClick={(e) => setContentType(ContentSettingsType.Default)}
                                        onChange={() => {}}
                                    ></input>
                                    <label
                                        className="custom-control-label"
                                        htmlFor="default"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Default (Recommend)
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div className="pb-4">
                                    Automatically assigns questions based on student assessment
                                    data.
                                </div>

                                <div style={{ fontWeight: 'lighter' }}>
                                    Demo Class: Number lines, Counting, Number in words, Ordering
                                    and comparing, Multiplication, Subtraction
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pb-2">
                        <div
                            className=""
                            style={{
                                border: '1px solid black',
                                borderRadius: 5,
                                padding: 10,
                            }}
                        >
                            <div className="custom-control custom-radio ">
                                <div>
                                    <input
                                        key="radio-content-settings-custom"
                                        id="custom"
                                        name="contentSettings"
                                        type="radio"
                                        className="custom-control-input"
                                        checked={contentType === ContentSettingsType.Custom}
                                        onClick={(e) => setContentType(ContentSettingsType.Custom)}
                                        onChange={() => {}}
                                    ></input>
                                    <label
                                        className="custom-control-label"
                                        htmlFor="custom"
                                        style={{ fontWeight: 'bold' }}
                                    >
                                        Custom
                                    </label>
                                </div>
                            </div>
                            <div>
                                <div className="pb-4">
                                    Choose specific Key Concepts for students to work on.
                                </div>
                                <KeyConceptPicker
                                    isDisabled={contentType !== ContentSettingsType.Custom}
                                    keyConcepts={props.sessionData.keyConcepts}
                                    selectedKeyConceptIds={focusKeyConceptIds}
                                    onSelectedKeyConceptIdsChange={(ids) => {
                                        setFocusKeyConceptIds(ids)
                                    }}
                                ></KeyConceptPicker>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ display: 'block', textAlign: 'center', borderStyle: 'none' }}>
                    <div>
                        {!selectedStudentId && (
                            <div className="w-full mb-4">
                                <input
                                    type="checkbox"
                                    id="override-settings"
                                    name="override-settings"
                                    checked={contentSettingsOverride}
                                    className="ml-2"
                                    onChange={(e) => setContentSettingsOverride(e.target.checked)}
                                />
                                <label
                                    htmlFor="scales"
                                    className="ml-2"
                                    onClick={(e) =>
                                        setContentSettingsOverride(!contentSettingsOverride)
                                    }
                                >
                                    Override students with individual settings?
                                </label>
                            </div>
                        )}
                        <Button
                            color="success"
                            onClick={() => {
                                toggleContentModalVisible()
                                saveContentSettings()
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
            <Modal size="lg" isOpen={gameModalVisible} toggle={toggleGameModalVisible} style={{}}>
                <ModalHeader style={{ display: 'block', textAlign: 'center', borderStyle: 'none' }}>
                    Spaced Practice Game Settings
                    <h6 style={{ display: 'block', textAlign: 'center', paddingTop: 10 }}>
                        Control how frequently students access games during Spaced Practice.
                    </h6>
                </ModalHeader>
                <ModalBody style={{ padding: 50, paddingBottom: 10, paddingTop: 10 }}>
                    <div className="row">
                        {gameRatioOptions.map((v, index) => {
                            return (
                                <div className="col p-1" key={index} style={{ maxWidth: 140 }}>
                                    <div
                                        className="col"
                                        style={{
                                            border: '1px solid black',
                                            borderRadius: 5,
                                            padding: 10,
                                        }}
                                    >
                                        <div className="custom-control custom-radio">
                                            <div>
                                                <input
                                                    id={'games-ratio-type-' + index}
                                                    name="gamesRatioType"
                                                    type="radio"
                                                    className="custom-control-input"
                                                    checked={gamesRatioType === v.value}
                                                    onClick={(e) => setGamesRatioType(v.value)}
                                                    onChange={() => {}}
                                                ></input>
                                                <label
                                                    className="text-nowrap custom-control-label"
                                                    htmlFor={'games-ratio-type-' + index}
                                                    style={{ fontSize: '13px' }}
                                                >
                                                    {v.questionLabel}
                                                    <br></br>
                                                    {v.gameLabel}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </ModalBody>
                <ModalFooter style={{ display: 'block', textAlign: 'center', borderStyle: 'none' }}>
                    <div className="w-full mb-4">
                        <input
                            type="checkbox"
                            id="override-settings"
                            name="override-settings"
                            className="ml-2"
                            checked={gameRatioOverride}
                            onChange={(e) => setGameRatioOverride(e.target.checked)}
                        />
                        <label
                            htmlFor="scales"
                            className="ml-2"
                            onClick={(e) => setGameRatioOverride(!gameRatioOverride)}
                        >
                            Override students with individual settings?
                        </label>
                    </div>
                    <Button
                        color="success"
                        onClick={() => {
                            toggleGameModalVisible()
                            saveGameSettings(null, gamesRatioType)
                        }}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

function dateIsInvalid(val: string) {
    let m = moment(val, 'YYYY-MM-DD')
    if (!m.isValid()) return true

    if (m.year() < 1000 || m.year() > 3000) return true

    return false
}
